<template>
  <div v-html="apply" />
</template>

<script>
export default {
  name: 'PaymentZfb',
  data() {
    return {
      apply: ''
    }
  },
  mounted() {
    this.apply = this.$route.query.htmls
    this.$nextTick(() => {
      document.forms[0].submit()
    })
  }
}
</script>

<style scoped>

</style>
